import React,{useEffect} from "react";
import { Button, Divider, Form, message, Steps, theme } from "antd";
import { useState } from "react";
import BasicDetailsForm from "./BasicDetailsForm";
import NextofKinForm from "./NextofKinForm";
import ProposerForm from "./ProposerForm";
import DeclarationForm from "./DeclarationForm";
import  styles from "./MembershipForm.module.css";
import { Link, useNavigate } from "react-router-dom";
import MembershipFormSubmission from "./MembershipFormSubmission";
import moment from "moment";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { validateEmail } from "../../utils/emailValidation";
function isValidEmail(email) {
  var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
}
function MembershipForm() {
  const [current, setCurrent] = useState(0);
  const [membershipFormData,setMembershipFormData]=useState();
  const [formData, setFormData] = useState({
    remember: false,
    remember2: false,
    country_code:'+65'
  });
  const [imageUrl, setImageUrl] = useState();
  const [fileList, setFileList] = useState();
  const [isNumber, setIsNumber] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [membershipFormType,setMembershipFormType]=useState("new");
  const [formVisible,setFormVisible]=useState("");
  const [successModal,setSuccessModal]=useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const membership_id = searchParams.get("id");
  const category = location.state.category;
  const membershipType= location.state.membershipFormType
  const navigate = useNavigate();
  useEffect(()=>{
    if(!category || !membership_id){
      navigate("/Membership")
    }
    if(membershipType==="renewal"){
      setMembershipFormType("renewal")
    }
  },[])
  // const category = searchParams.get("category");
  let uploadeProps = {
    imageUrl,
    setImageUrl,
    fileList,
    setFileList,
  };
  const thumbnailImage = async () => {
    let imageUrl = "";

    const formData = new FormData();
    console.log(fileList);
    formData.append("file", fileList);
    formData.append("type", "new_member");
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/upload-images`,
      data: formData,
      // headers: {
      //   "content-type": "application/json",
      //    Authorization: localStorage.getItem('token')
      // },
    })
      .then(function (response) {
        console.log(response);
        if (response.data.success) {
          console.log("response", response.data.url);
          imageUrl = response.data.url;
          // setImagesList([...imagesList, { url: response.data.url }])
          // return true;
        }
        // return false
      })
      .catch(function (error) {
        console.log(error);
        // return false
      });

    return imageUrl;
  };

  const checkForUser = async(membershipId)=>{

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/check-proposer-seconder`,
        data:{
          proposerData: [
            {
              type: "proposer",
              name: formData.proposer_name,
              membership_no: formData.proposer_membership_no,
              phone: formData.proposer_mobile_number,
              home_c_num: formData.proposer_home_contact_number,
            },
            {
              type: "secondar",
              name: formData.secondar_name,
              membership_no: formData.secondar_membership_no,
              phone: formData.secondar_mobile_number,
              home_c_num: formData.secondar_home_contact_number,
            },
          ],
        }
      });
      
      if (response.data.status) {
        return response.data.status;
      } else {
        message.error(response.data.message)
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
    
  }
  const next = async () => {
    console.log(formData)
    if (current===0 && (formData.home_contact_number && formData.office_contact_number) &&  (formData.home_contact_number === formData.office_contact_number)) {
      message.error("Home Contact Number and Office Contact Number should be different !")
      return
    }
    if (current===1 && (formData.emergency_1_name && formData.emergency_2_name) &&  (formData.emergency_1_name === formData.emergency_2_name)) {
      message.error("Emergency contact person 1 and person 2 name should be different !")
      return
    }
    if (current===1 && (formData.emergency_1_mobile_number && formData.emergency_2_mobile_number) &&  (formData.emergency_1_mobile_number === formData.emergency_2_mobile_number)) {
      message.error("Emergency contact person 1 and person 2 mobile number should be different !")
      return
    }
    if (current===2 && (formData.proposer_name && formData.secondar_name) &&  (formData.proposer_name === formData.secondar_name)) {
      message.error("Proposer and Secondar name should be different !")
      return
    }
    if (current===2 && (formData.proposer_mobile_number && formData.secondar_mobile_number) &&  (formData.proposer_mobile_number === formData.secondar_mobile_number)) {
      message.error("Proposer and Secondar mobile number should be different !")
      return
    }
    if (current===2 && (formData.proposer_membership_no && formData.secondar_membership_no) &&  (formData.proposer_membership_no === formData.secondar_membership_no)) {
      message.error("Proposer and Secondar membership number should be different !")
      return
    }
    // if (current===1 && (formData.emergency_1_home_contact_number && formData.emergency_2_home_contact_number) &&  (formData.emergency_1_home_contact_number === formData.emergency_2_home_contact_number)) {
    //   message.error("Emergency contact person home contact number should be different !")
    //   return
    // }
    // if (current===0 && (!formData.title||
    //   !formData.name_as_per_NRIC||
    //   !formData.name_to_be_printed_on_card||
    //   !formData.join_date||
    //   !formData.date_of_birth||
    //   !formData.race||
    //   !formData.nationality||
    //   !formData.NRIC_and_FIN_no||
    //   !formData.email||
    //   !formData.marital_status||
    //   !formData.mobile_number||
    //   !formData.home_contact_number||
    //   !formData.office_contact_number||
    //   !formData.occupation||
    //   !formData.company_name||
    //   !formData.block_no||
    //   !formData.street_no||
    //   !formData.level_no||
    //   !formData.unit_no||
    //   !formData.postal_code||
    //   !formData.country)) {
    //     console.log(
    //       formData.title,
    //       formData.name_as_per_NRIC,
    //       formData.name_to_be_printed_on_card,
    //       formData.join_date,
    //       formData.date_of_birth,
    //       formData.race,
    //       formData.nationality,
    //       formData.NRIC_and_FIN_no,
    //       formData.email,
    //       formData.marital_status,
    //       formData.mobile_number,
    //       formData.home_contact_number,
    //       formData.office_contact_number,
    //       formData.occupation,
    //       formData.company_name,
    //       formData.block_no,
    //       formData.street_no,
    //       formData.level_no,
    //       formData.unit_no,
    //       formData.postal_code,
    //       formData.country,
    //     );
    //     setCurrent(current + 1);
    //     return
    // }
    if (
      current === 0 &&
      (!formData.name_as_per_NRIC ||
        !formData.name_to_be_printed_on_card ||
        !formData.date_of_birth ||
        !formData.NRIC_and_FIN_no ||
        !formData.marital_status ||
        !formData.mobile_number ||
        // !formData.home_contact_number ||
        // !imageUrl||
        !formData.occupation ||
        !formData.company_name ||
        !formData.race||
        !formData.gender||
        !formData.unit_no ||
        !formData.postal_code||
        formData.mobile_number.length >= 10 || !formData.email || formData.email)
    ) {
      console.log(formData);
      // if(!imageUrl){
      //   message.error("Please Upload Profile Picture");
      //   return;
      // }
      if (!formData.name_as_per_NRIC) {
        message.error("Please Fill Name As Per NRIC");
        return;
      }
      if (!formData.gender) {
        message.error("Please Fill Gender");
        return;
      }
      if (!formData.name_to_be_printed_on_card) {
        message.error("Please Fill Name To Be Printed On Card");
        return;
      }
      // if (!formData.join_date) {
      //   message.error("Please Fill Join date");
      //   return;
      // }
      if (!formData.date_of_birth) {
        message.error("Please Fill Date Of Birth");
        return;
      }
      if (!formData.unit_no) {
        message.error("Please Fill Unit Number");
        return;
      }
      if (!formData.postal_code) {
        message.error("Please Fill Postal Code");
        return;
      }
      if (!formData.NRIC_and_FIN_no) {
        message.error("Please Fill NRIC And  FIN  No.");
        return;
      }
      if(formData.NRIC_and_FIN_no &&membershipFormType!=="renewal"){
      try{
        let data = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/check-nric`,{
            nric_fin_no:formData.NRIC_and_FIN_no
          }
        );
        let res = data.data;
        console.log(res.success);
        if (res.success === false) {
          message.error("NRIC/FIN already exists!");
          return;
        } else {
          console.log(res.success);
        }
      }
      catch(err){
        // console.log(err.response.data.Error);
        // message.error(err.response.data.Error);
        message.error("NRIC/FIN already exists!");
        return;
      }
      }
      if (!formData.email) {
        message.error("Please Fill Email.");
        return;
      } 
      if(!formData.race){
        message.error("Please Fill Race.");
        return;
      }
      if (!isValidEmail(formData.email)) {
        message.error("Please Enter Valid Email.");
        return;
      }
      if (formData.email &&membershipFormType!=="renewal" && isValidEmail(formData.email)) {
        let data = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/check-member-form?email=${formData.email}`
        );
        let res = data.data;
        console.log(res.success);
        if (res.success === false) {
          message.error("Email Is Already Exist !");
          return;
        } else {
          console.log(res.success);
        }
      }
      if (!formData.marital_status) {
        message.error("Please Fill Marital Status");
        return;
      }
      if (!formData.mobile_number) {
        message.error("Please Fill Mobile Number");
        return;
      }
      if (formData.mobile_number.length >= 8 && membershipFormType!=="renewal") {  
        // check number is exist or not
        let data = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/check-member-form?phone=${formData.mobile_number}`
        );
        let res = data.data;
        console.log(res.success);
        if (res.success === false) {
          message.error("Phone Number Already Exist !");
          return;
        } else {
          console.log(res.success);
        }
      }
      // if (!formData.home_contact_number) {
      //   message.error("Please Fill Home Contact Number");
      //   return;
      // }
      if (!formData.occupation) {
        message.error("Please Fill Occupation");
        return;
      }
      if (!formData.company_name) {
        message.error("Please Fill Conpany Name");
        return;
      }
      if(!formData.country){
        message.error("Please Fill Country");
        return;
      }
    }
    if(current===0){
      const male = ["Dr","Mr"]
      const female = ["Mrs","Ms","Mdm","Miss","Dr"]
      const student = ["Miss","Mr"];
      if(category!==formData.gender && category!=="student"){
        message.error("Please Select Gender Respected to Plan !");
        return;
      }
      if(category !=="student"){

        const categories = category==="male"?male:female
        if(!categories.includes(formData.title)){
          message.error("Please Select Title Respected to Plan !");
          return;
        }
      }
      else{
        if(!student.includes(formData.title)){
          message.error("Please Select Title Respected to Plan !");
          return;
        }
        if(formData.gender==="male" && formData.title!=="Mr"){
          message.error("Please Select Title Respected to Gender !");
          return;    
        }
        if(formData.gender==="female" && formData.title!=="Miss"){
          message.error("Please Select Title Respected to Gender !");
          return;
        }
      }
      if(formData.email){
        if(!validateEmail(formData.email)){
          message.error("Please Enter Valid Email !");
          return;
        }
      }
      
    }
    if (current === 0) {
      let img = await thumbnailImage();
      if(img){

        setPhoto(img);
      }
      else{
        setPhoto(imageUrl);
      }
    }
    if (
      current === 1 &&
      (!formData.emergency_1_name ||
        !formData.emergency_1_relationship ||
        !formData.emergency_1_mobile_number ||
        // !formData.emergency_1_home_contact_number ||
        !formData.emergency_2_name ||
        !formData.emergency_2_relationship ||
        !formData.emergency_2_mobile_number 
        // !formData.emergency_2_home_contact_number
        )
    ) {
      if (!formData.emergency_1_name) {
        message.error("Please Fill Person 1 Name");
        return;
      }

      if (!formData.emergency_1_relationship) {
        message.error("Please Fill Person 1 Relationship");
        return;
      }

      if (!formData.emergency_1_mobile_number) {
        message.error("Please Fill Person 1 Mobile Number");
        return;
      }

      // if (!formData.emergency_1_home_contact_number) {
      //   message.error("Please Fill Person 1 Home Contact Number");
      //   return;
      // }
      if (!formData.emergency_2_name) {
        message.error("Please Fill Person 2 Name");
        return;
      }

      if (!formData.emergency_2_relationship) {
        message.error("Please Fill Person 2 Relationship");
        return;
      }

      if (!formData.emergency_2_mobile_number) {
        message.error("Please Fill Person 2 Mobile Number");
        return;
      }

      // if (!formData.emergency_2_home_contact_number) {
      //   message.error("Please Fill Person 2 Home Contact Number");
      //   return;
      // }
    }
    if (
      current === 2 &&
      (!formData.proposer_name ||
        !formData.proposer_membership_no ||
        !formData.proposer_mobile_number ||
        // !formData.proposer_home_contact_number ||
        !formData.secondar_name ||
        !formData.secondar_membership_no ||
        !formData.secondar_mobile_number 
        // ! || formData.secondar_home_contact_number
        )
    ) {
      if (!formData.proposer_name) {
        // Handle missing proposer_name
        message.error("Please Fill Proposer Name");
        return;
      }

      if (!formData.proposer_membership_no) {
        // Handle missing proposer_membership_no
        message.error("Please Fill Proposer Membership No");
        return;
      }

      // if (!formData.proposer_mobile_number) {
        
      //   message.error("Please Fill Proposer Mobile Number");
      //   return;
      // }
      // Handle missing proposer_mobile_number

      // if (!formData.proposer_home_contact_number) {
      //   // Handle missing proposer_home_contact_number
      //   message.error("Please Fill Proposer Home Contact Number");
      //   return;
      // }

      if (!formData.secondar_name) {
        // Handle missing secondar_name
        message.error("Please Fill Secondar Name");
        return;
      }

      if (!formData.secondar_membership_no) {
        // Handle missing secondar_membership_no
        message.error("Please Fill Secondar Membership No");
        return;
      }

      // if (!formData.secondar_mobile_number) {
        
      //   message.error("Please Fill Secondar Mobile Number");
        // return;
      // }
      // Handle missing secondar_mobile_number

      // if (!formData.secondar_home_contact_number) {
      //   // Handle missing secondar_home_contact_number
      //   message.error("Please Fill Secondar Home Contact Number");
      //   return;
      // }
     
    }

    if(current===2){
    

      var proposer= await checkForUser(formData.proposer_membership_no)
      .then(function (result) {
        console.log(result);
        return result; // true or false
      })
      .catch(function (error) {
        console.log(error);
      });
      if(!proposer){
        // console.log(proposer);
        // message.error("There is no Proposer present with this membership number")
        return;
      }
      var secondar = await checkForUser(formData.secondar_membership_no)
      .then(function (result) {
        console.log(result);
        return result; // true or false
      })
      .catch(function (error) {
        console.log(error);
      });


   
    if(!secondar){
      // console.log(secondar);
      // message.error("There is no Secondar present with this membership number")
      return;
    }
    }
    
    setCurrent(current + 1);
    return;
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const onSubmit = () => {
    if (
      current === 3 &&
      (
        !formData.terms_years ||
        // !formData.is_membership_card||
        formData.remember2 === false ||
        formData.remember === false || !photo)
    ) {
      // if (!formData.membership_term) {
      //   message.error("Please Select Membership Term");
      //   return;
      // }
      // if (!formData.terms_years) {
      //   message.error("Please Select Term Year");
      //   return;
      // }
      // if (formData.is_membership_card!==0 && formData.is_membership_card!==1) {
      //   message.error("Please Select Do you want Physical Card");
      //   return;
      // }
      
      if (formData.remember === false) {
        message.error("Please Check Declaration !");
        return;
      }
      if (formData.remember2 === false) {
        message.error("Please Check Declaration !");
        return;
      }
      if (!photo) {
        console.log(photo);
      }
    }
    console.log(formData)
    let data = {
      name: formData.name_to_be_printed_on_card,
      email: formData.email,
      country_code:formData.country_code,
      phone: `${formData.mobile_number}`,
      date_of_application: moment(formData.join_date).format("YYYY-MM-DD"),
      date_of_birth: moment(formData.date_of_birth).format("YYYY-MM-DD"),
      image_path: photo,
      membership_id: membership_id,
      //   membership_type: formData.name_to_be_printed_on_card,
      //   address: formData.name_to_be_printed_on_card,
      title: formData.title,
      name_nric_fin: formData.name_as_per_NRIC,
      race: formData.race,
      nationality: formData.nationality,
      nric_fin_no: formData.NRIC_and_FIN_no,
      marital_status: formData.marital_status,
      home_c_num: formData.home_contact_number,
      office_c_num: formData.office_contact_number,
      occupation: formData.occupation,
      company_name: formData.company_name,
      block_no: formData.block_no,
      street_no: formData.street_no,
      level_no: formData.level_no,
      unit_no: formData.unit_no,
      postal_code: formData.postal_code,
      country: formData.country,
      gender: formData.gender,
      membership_period_new:formData.membership_period_new||null,
      emergencyData: [
        {
          persone_type: "person1",
          name: formData.emergency_1_name,
          relationship: formData.emergency_1_relationship,
          phone: formData.emergency_1_mobile_number,
          home_c_num: formData.emergency_1_home_contact_number||null,
        },
        {
          persone_type: "person2",
          name: formData.emergency_2_name,
          relationship: formData.emergency_2_relationship,
          phone: formData.emergency_2_mobile_number,
          home_c_num: formData.emergency_2_home_contact_number||null,
        },
      ],
      proposerData: [
        {
          type: "proposer",
          name: formData.proposer_name,
          membership_no: formData.proposer_membership_no,
          phone: formData.proposer_mobile_number,
          home_c_num: null,
        },
        {
          type: "secondar",
          name: formData.secondar_name,
          membership_no: formData.secondar_membership_no,
          phone: formData.secondar_mobile_number,
          home_c_num: null,
        },
      ],
      term: membershipFormType,
      term_year: 0,
      // term_year: formData.terms_years,
      is_membership_card:formData.is_membership_card,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/membershipform/new`, data)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setsubmitted(true);
          message.success("Member Registered Successfully !");
        }
        else{
          message.warn(res.data.message)
          // message.warn("Please Fill all required fields");
        }
      })
      .catch((err) => {
        message.warn(err.response.data.errors[0].field+" "+err.response.data.errors[0].message);
      });
    console.log(data);
  };
  const onInputHandleChange = (name, value) => {
    console.log(name, value);
    setFormData((preval) => {
      return {
        ...preval,
        [name]: value,
      };
    });
    console.log(formData);
  };



  useEffect(()=>{
    if(membershipFormType==="new"){
      form.resetFields()
      setFormData({
        remember: false,
        remember2: false,
        country_code:'+65'
      });
      setImageUrl();
       setFormVisible(true);
      setSuccessModal();
    }
    else{
    setFormVisible(false);
    setSuccessModal();
    }
  },[membershipFormType])
  
  
  const [submitted, setsubmitted] = useState(false);

   
    const [form] = Form.useForm();
  useEffect(()=>{
    if(membershipFormType==="renewal"){
      console.log(membershipFormData.image_path);
      setImageUrl([
        membershipFormData.image_path,
      ])
      form.setFieldsValue({
        'title':membershipFormData.title,
         "name_as_per_NRIC":membershipFormData.name_nric_fin,
         "name_to_be_printed_on_card":membershipFormData.name,
         "company_name":membershipFormData.company_name,
         "date_of_birth":moment(membershipFormData.date_of_birth),
         "race":membershipFormData.race,
         "nationality":membershipFormData.nationality,
         "gender":membershipFormData.gender,
         "NRIC_and_FIN_no":membershipFormData.nric_fin_no,
         "email":membershipFormData.email,
         "marital_status":membershipFormData.marital_status,
         "country_code":membershipFormData.country_code,
         "mobile_number":membershipFormData.phone,
         "home_contact_number":membershipFormData.home_c_num,
         "office_contact_number":membershipFormData.office_c_num,
         "occupation":membershipFormData.occupation,
         "block_no":membershipFormData.block_no,
         "street_no":membershipFormData.street_no,
         "level_no":membershipFormData.level_no,
         "unit_no":membershipFormData.unit_no,
         "postal_code":membershipFormData.postal_code,
         "country":membershipFormData.country,
         "emergency_1_name":membershipFormData.emergency_contacts[0].name,
         "emergency_1_relationship":membershipFormData.emergency_contacts[0].relationship,
         "emergency_1_mobile_number":membershipFormData.emergency_contacts[0].phone,
         "emergency_1_home_contact_number":membershipFormData.emergency_contacts[0].home_c_num,
          "emergency_2_name":membershipFormData.emergency_contacts[1].name,
         "emergency_2_relationship":membershipFormData.emergency_contacts[1].relationship,
         "emergency_2_mobile_number":membershipFormData.emergency_contacts[1].phone,
         "emergency_2_home_contact_number":membershipFormData.emergency_contacts[1].home_c_num,
        "proposer_name":membershipFormData.membership_proposers[0].name,
        "proposer_membership_no":membershipFormData.membership_proposers[0].membership_no,
        "proposer_mobile_number":membershipFormData.membership_proposers[0].phone,
        "proposer_home_contact_number":membershipFormData.membership_proposers[0].home_c_num,
        "secondar_name":membershipFormData.membership_proposers[1].name,
        "secondar_membership_no":membershipFormData.membership_proposers[1].membership_no,
        "secondar_mobile_number":membershipFormData.membership_proposers[1].phone,
        "secondar_home_contact_number":membershipFormData.membership_proposers[1].home_c_num,
        "membership_term":membershipFormData.term,
        // "terms_years":membershipFormData.term_year,
        "is_membership_card":membershipFormData.is_membership_card,
         
    })

    // proposerData: [
    //   {
    //     type: "proposer",
    //     name: formData.proposer_name,
    //     membership_no: formData.proposer_membership_no,
    //     phone: formData.proposer_mobile_number,
    //     home_c_num: formData.proposer_home_contact_number,
    //   },
    //   {
    //     type: "secondar",
    //     name: formData.secondar_name,
    //     membership_no: formData.secondar_membership_no,
    //     phone: formData.secondar_mobile_number,
    //     home_c_num: formData.secondar_home_contact_number,
    //   },
    // ],

    setFormData({
      'title':membershipFormData.title,
         "name_as_per_NRIC":membershipFormData.name_nric_fin,
         "name_to_be_printed_on_card":membershipFormData.name,
         "company_name":membershipFormData.company_name,
         "date_of_birth":moment(membershipFormData.date_of_birth),
         "race":membershipFormData.race,
         "gender":membershipFormData.gender,
         "nationality":membershipFormData.nationality,
         "NRIC_and_FIN_no":membershipFormData.nric_fin_no,
         "email":membershipFormData.email,
         "marital_status":membershipFormData.marital_status,
         "country_code":membershipFormData.country_code,
         "mobile_number":membershipFormData.phone,
         "home_contact_number":membershipFormData.home_c_num,
         "office_contact_number":membershipFormData.office_c_num,
         "occupation":membershipFormData.occupation,
         "block_no":membershipFormData.block_no,
         "street_no":membershipFormData.street_no,
         "level_no":membershipFormData.level_no,
         "unit_no":membershipFormData.unit_no,
         "postal_code":membershipFormData.postal_code,
         "country":membershipFormData.country,
          "emergency_1_name":membershipFormData.emergency_contacts[0].name,
         "emergency_1_relationship":membershipFormData.emergency_contacts[0].relationship,
         "emergency_1_mobile_number":membershipFormData.emergency_contacts[0].phone,
         "emergency_1_home_contact_number":membershipFormData.emergency_contacts[0].home_c_num,
          "emergency_2_name":membershipFormData.emergency_contacts[1].name,
         "emergency_2_relationship":membershipFormData.emergency_contacts[1].relationship,
         "emergency_2_mobile_number":membershipFormData.emergency_contacts[1].phone,
         "emergency_2_home_contact_number":membershipFormData.emergency_contacts[1].home_c_num,
        "proposer_name":membershipFormData.membership_proposers[0].name,
        "proposer_membership_no":membershipFormData.membership_proposers[0].membership_no,
        "proposer_mobile_number":membershipFormData.membership_proposers[0].phone,
        "proposer_home_contact_number":membershipFormData.membership_proposers[0].home_c_num,
        "secondar_name":membershipFormData.membership_proposers[1].name,
        "secondar_membership_no":membershipFormData.membership_proposers[1].membership_no,
        "secondar_mobile_number":membershipFormData.membership_proposers[1].phone,
        "secondar_home_contact_number":membershipFormData.membership_proposers[1].home_c_num,
        "membership_term":membershipFormData.term,
        // "terms_years":membershipFormData.term_year,
        "is_membership_card":membershipFormData.is_membership_card
    })
    }
  },[membershipFormData])
  const steps = [
    {
      title: "Basic Details",
      content: (
        <Form form={form}>  
        <BasicDetailsForm
          membershipFormType={membershipFormType}
          setMembershipFormType={setMembershipFormType}
          formVisible={formVisible}
          setFormVisible={setFormVisible}
          uploadeProps={uploadeProps}
          onInputHandleChange={onInputHandleChange}
          setMembershipFormData={setMembershipFormData}
          membershipFormData={membershipFormData}
          setSuccessModal={setSuccessModal}
          successModal={successModal}
        />
        </Form>
      ),
    },
    {
      title: "Next of KIN",
      content: (
        <Form form={form}>  
        <NextofKinForm onInputHandleChange={onInputHandleChange} />
        </Form>),
    },
    {
      title: "Proposer & Seconder",
      content: (
        <Form form={form}> 
      <ProposerForm onInputHandleChange={onInputHandleChange} />
      </Form>),
    },
    {
      title: "Declaration",
      content:
      (
        <Form form={form}>  <DeclarationForm formData={formData} onInputHandleChange={onInputHandleChange} />
         </Form>),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  useEffect(() => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth"
    });
  }, [])
  return (
    <div style={{ overflow: "hidden" }} className="marginTopFromNavbar">
      <div className={styles.header_new}>
        <div className={styles.text_container}>
          <div className={styles.text}>MEMBERSHIP</div>
        </div>
      </div>
      <div style={{ margin: "0px 60px" }}>
        {!submitted && (
          <>
            <Steps
              direction="horizontal"
              style={{ maxWidth: "700px", margin: "0px auto" }}
              labelPlacement="vertical"
              current={current}
              items={items}
            />
            <Divider />
            <Form style={{ fontWeight: "500" }} layout="vertical">
              <div>{steps[current].content}</div>
            </Form>
            <div
              style={{
                marginTop: 24,
              }}
            >
              {current > 0 && (
                <button
                  className={`${styles.membershipFormButton} ${styles.previousBtn}`}
                  style={{ marginRight: "20px" }}
                  onClick={() => prev()}
                >
                  BACK
                </button>
              )}
              {current < steps.length - 1 && formVisible && (
                <button className={styles.membershipFormButton} onClick={() => next()}>
                  NEXT
                </button>
              )}
              {current === steps.length - 1 && (
                <button
                  className={styles.membershipFormButton}
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  SUBMIT
                </button>
              )}
            </div>{" "}
          </>
        )}
       
      </div>
      {submitted && <MembershipFormSubmission />}
    </div>
  );
}

export default MembershipForm;
