import { Button, Form, Input, message } from "antd";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import validator from "validator";

function ConfirmPassword() {
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const email = query.get("email");
  const navigate  = useNavigate();
  useEffect(() => {
    if (!token || !email) {
      window.location.href = "/";
    }
  }, []);

  const onFinish = async (values) => {

    if (values.password !== values.confirm_password) {
      message.error("Passwords do not match");
      return;
    }
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v2/verify-password?email=${email}&token=${token}`,
        { ...values },
        { headers: { "Content-Type": "application/json" } }
      );
      console.log(res);
      if (res.data.success) {
        message.success("Password changed successfully");
        setLoading(false);
        navigate("/login");
        return;
      }
      else{
        message.error(res.data.msg);
        setLoading(false);
      }
    } catch (err) {
      // message.error(err.response)
      console.log(err.response);
      setLoading(false);
    }
  };
  const validatePassword = (rule, value, callback) => {
    if (validator.isStrongPassword(value, { 
      minLength: 5, minLowercase: 1, 
      minUppercase: 1, minNumbers: 1, minSymbols: 1 
  })) { 
    callback();
  } else { 
    callback('Password must contain at least 5 characters, 1 lowercase letter, 1 uppercase letter, 1 number and 1 symbol')
  } 
  };
  return (
    <div style={{ marginTop: "150px", marginBottom: "140px" }}>
      <div className="m-auto d-flex flex-wrap justify-content-center">
        <div className="login_information" style={{
          width: "100%",
          maxWidth: "400px",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px 0px #0000001a",
          backgroundColor: "#fff",
          border: "1px solid #f0f0f0",
        }}>
          <div className="login_info_head d-flex align-items-center justify-content-center">
            <MemberLoginIcon />{" "}
            <span className="ml-2" style={{ color: "#F31237" }}>
              {" "}
              Member Login{" "}
            </span>
          </div>
          <Form form={form} onFinish={onFinish}>
            <h4 className="mb-3">New Password</h4>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input your password",
                },
                { validator: validatePassword }
              ]}
              name="password"
            >
              <Input.Password onPaste={(e)=>{
                e.preventDefault();
                return false;
              }} />
            </Form.Item>
            <h4 className="mb-3">Confirm Password</h4>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input your password",
                },{
                  validator: validatePassword
                }
              ]}
              name="confirm_password"
            >
              <Input.Password onPaste={(e)=>{
                e.preventDefault();
                return false;
              }}/>
            </Form.Item>
            <Form.Item>
              <Button
                className="w-100"
                loading={loading}
                type="danger"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </Form>
          {/* <Email/> */}
        </div>
      </div>
    </div>
  );
}

export default ConfirmPassword;

const MemberLoginIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_967_1330)">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 6C13.93 6 15.5 7.57 15.5 9.5C15.5 11.43 13.93 13 12 13C10.07 13 8.5 11.43 8.5 9.5C8.5 7.57 10.07 6 12 6ZM12 20C9.97 20 7.57 19.18 5.86 17.12C7.55 15.8 9.68 15 12 15C14.32 15 16.45 15.8 18.14 17.12C16.43 19.18 14.03 20 12 20Z"
        fill="#F31237"
      />
    </g>
    <defs>
      <clipPath id="clip0_967_1330">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
