import { Button, message } from "antd";
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import moment from "moment";
// import Pdf from "../../../Assets/Membership Terms and Conditions.pdf";
import { CardElement, CardNumberElement } from "@stripe/react-stripe-js";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { paymentUrl } from "../../../Services/BusinessLogic";
import { useDispatch } from "react-redux";
import { setPayment } from "../../../redux/slice/paymentIntentSlice";

function EventsFaciltiyCheckout() {
  const storedToken = localStorage.getItem("token");
  const [nName, setnName] = useState("");
  const [nEmail, setnEmail] = useState("");
  const [nPhone, setnPhone] = useState("");
  const [check, setCheck] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const name = localStorage.getItem("name");
  const email = localStorage.getItem("email");
  const phone = localStorage.getItem("phone");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  // const stripe = useStripe();
  // const elements = useElements();

  const [Pdf, setPdf] = useState("");

  const getTermsAndConditions = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/cms`, {
        name: "terms_and_conditions",
      });
      console.log("pdf",res.data.result.content);
      setPdf(res.data.result.content);
    } catch (err) {
      message.error(err.message);
    }
  };
  const {
    amount,
    booking_hours,
    discount,
    end_date,
    end_time,
    no_of_pax,
    start_date,
    start_time,
    tax,
    total_amount,
    type,
    type_id,
    bookingName,
    guest,
    serviceCharge,
  } = location.state;

  // const createSubscription = async () => {
  //   try {

  //     // create a payment method
  //     const paymentMethod = await stripe?.createPaymentMethod({
  //       type: "card",
  //       card: elements.getElement(CardElement),
  //       billing_details: {
  //         name,
  //         email,
  //       },
  //     });

  //     return;
  //     // call the backend to create subscription
  //     const response = await fetch("http://localhost:4000/create-subscription", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         paymentMethod: paymentMethod?.paymentMethod?.id,
  //         name,
  //         email,
  //         // priceId
  //       }),
  //     }).then((res) => res.json());

  //     const confirmPayment = await stripe?.confirmCardPayment(
  //       response.clientSecret
  //     );

  //     if (confirmPayment?.error) {
  //       alert(confirmPayment.error.message);
  //     } else {
  //       return true;

  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const paymentApi = async (booking_id, type, total_amount, is_guest) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/payments/new`,
        {
          booking_id: booking_id,
          payment_intent_id: "123",
          payment_status: "paid",
          payment_mode: "online",
          type: type,
          total_amount: total_amount,
          is_guest: true,
          // user_id:
        },
        {
          headers: {
            content: "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      console.log(response.data);
      return true;
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const onBooking = async () => {
    // const res = await createSubscription();
    // if(!res){
    //   return;
    // }

    // to="/EventsFacilityBookingConfirmed"
    if (!storedToken && (!nName || !nEmail || !nPhone)) {
      message.error("Please fill all the fields");
      return;
    }
    const data = {
      name: storedToken ? name : nName,
      phone: storedToken ? phone : nPhone,
      email: storedToken ? email : nEmail,
      type: type,
      type_id: type_id,
      start_date: start_date,
      end_date: end_date,
      start_time: start_time,
      end_time: end_time,
      no_of_pax: no_of_pax + 1,
      amount: parseFloat(amount).toFixed(2),
      discount: discount,
      total_amount: !total_amount
        ? Number(parseFloat(amount).toFixed(2))+Number(parseFloat(tax).toFixed(2))+Number(parseFloat(serviceCharge).toFixed(2))
        : Number(parseFloat(total_amount).toFixed(2))+Number(parseFloat(tax).toFixed(2))+Number(parseFloat(serviceCharge).toFixed(2)),
      tax: tax,
      booking_hours: booking_hours,
      other_details: {
        guest: guest,
        // "adult":adult,
        // "childeren":child
      },
    };

    if (check) {
      try {
        setLoading(true);
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/bookings/new-booking`,
          data
        );

        console.log(res.data);
        if (!res.data.status) {
          message.error(res.data.message);
          return;
        }
        const Link = await paymentUrl(
          res.data.result.id,
          res.data.result.user_id,
          data.type,
          parseFloat(total_amount).toFixed(2),
          bookingName,
          null,
          null,

          parseFloat(tax)?.toFixed(2),
          parseFloat(serviceCharge)?.toFixed(2)
        );
        if (Link) {
          dispatch(setPayment(Link.paymentId));
          window.location.href = Link.paymentLink;
        }
        console.log(Link);
        setLoading(false);
        return;
        // const payment = await paymentApi(res.data.result,data.type,data.total_amount)
        // if(payment){
        //  navigate(
        //   `/EventsFacilityBookingConfirmed?id=${res.data.result}&type=${data.type}&start_data=${start_date}&end_data=${end_date}&total_amount=${total_amount}&bookingName=${bookingName}`,{
        //   }
        // );
        // }
      } catch (err) {
        message.error(err.response.data.message);
        setLoading(false);
        return;
      }
    } else {
      message.error("Please Check to acknowledge our Privacy & Terms Policy");
    }
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth"
    });
    getTermsAndConditions();
  }, []);
  return (
    <div className="marginTopFromNavbar">
      {/* <div className="events_component_1">
      <div className="events_1">
        <h1>EVENTS FACILITY</h1>
      </div>
      <div className="events_2">HOME - Bookings - Events Facility - Checkout</div>
    </div> */}
      {/* <div className="header_new">
          <div className="text-container">
            <div className="text">EVENTS FACILITY</div>
            <div className="text2">HOME - Bookings - Events Facility - Checkout</div>
          </div>
      </div> */}
      <div className="checkout">
        <div className="membership_summary">
          <div className="membership_head">Booking Summary</div>
          <div className="membership_plan">
            <div className="membership_plan_">
              <div>{bookingName}</div>
              {/* <div>S${total_amount}.00</div> */}
            </div>
            <div>
              Date: {moment(start_date).format("DD MMM YYYY")} Time:{" "}
              {`${moment(start_time, "HH:mm:ss").format("hh:mm a")} - ${moment(
                end_time,
                "HH:mm:ss"
              ).format("hh:mm a")}`}
            </div>
          </div>
          {/* <label className="discount">Discount Code</label>
          <div class="buttonInside">
            <input placeholder="%" />
            <button id="apply">Apply</button>
          </div> */}

          <div className="checkout_price">
            <div>
              <div className="price_title">Subtotal</div>
              <div className="price">
                S$
                {!total_amount
                  ? parseFloat(amount)?.toFixed(2)
                  : parseFloat(total_amount)?.toFixed(2)}
              </div>
            </div>
            <div>
              <div className="price_title">GST</div>
              <div className="price">S${parseFloat(tax).toFixed(2)}</div>
            </div>
            <div>
              <div className="price_title">Service Charge</div>
              <div className="price">
                S${parseFloat(serviceCharge).toFixed(2)}
              </div>
            </div>
            {/* <div>
              <div className="price_title">Discount</div>
         
              <div className="price">-S${discount?.toFixed(2)}</div>
            </div> */}
          </div>
          <div className="checkout_total">
            <div className="price_title">Total</div>
            <div className="price">
              S$
              {!total_amount
                ? (parseFloat(amount) + parseFloat(tax))?.toFixed(2)
                : (
                    parseFloat(total_amount) +
                    parseFloat(tax) +
                    parseFloat(serviceCharge)
                  )?.toFixed(2)}
            </div>
          </div>
          <div
            className="d-flex mb-4"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "15px",
            }}
          >
            <label style={{ fontSize: "16px" }}>Name</label>
            <input
              className="inputCheck"
              type="text"
              name=""
              id=""
              placeholder="Name"
              value={name ? name : nName}
              onChange={(e) => {
                if (name) {
                  return;
                }
                  setnName(e.target.value);
              }}
              onKeyDown={(e) => { 
                if (e.key === "Backspace" || e.key === "Enter") {
                  return;
                }         
                if (!/^[a-zA-Z ]$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </div>
          <div
            className="d-flex mb-4"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <label style={{ fontSize: "16px" }}>Email</label>
            <input
              className="inputCheck"
              type="email"
              name=""
              id=""
              placeholder="Email"
              value={email ? email : nEmail}
              onChange={(e) => {
                if (email) {
                  return;
                }
                setnEmail(e.target.value);
              }}
            />
          </div>

          <div
            className="d-flex mb-4"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <label style={{ fontSize: "16px" }}>Phone number</label>
            <input
              type="Text"
              className="inputCheck"
              placeholder="Phone number"
              value={phone ? phone : nPhone}
              onChange={(e) => {
               
                if (phone) {
                  return;
                }
                 
                setnPhone( e.target.value);
                 
              }}
              onKeyDown={(e) => {
                if (e.key === "Backspace" || e.key === "Enter") {
                  return;
                }
                if (!/^[0-9]$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </div>
          <div className="t_and_c">
            <input
              style={{
                top: 0,
              }}
              type="checkbox"
              name=""
              id="checkbox"
              onChange={(e) => setCheck(e.target.checked)}
              value={check}
            />
            <div>
              Please check to acknowledge our{" "}
              <a style={{ display: "inline" }} target="_blank" href={Pdf}>
                Privacy & Terms Policy
              </a>
            </div>
          </div>
          <div onClick={onBooking}>
            <Button
              type="primary"
              danger
              loading={loading}
              style={{ width: "100%", height: "50px" }}
              className="text-center d-block"
            >
              Pay S$
              {!total_amount
                ? (parseFloat(amount) + parseFloat(tax)).toFixed(2)
                : (
                    parseFloat(total_amount) +
                    parseFloat(tax) +
                    parseFloat(serviceCharge)
                  ).toFixed(2)}
            </Button>
          </div>
        </div>

        {/* <div className="checkout_form">
          
          <div>
            <label>Email</label>
            <input
              className="login-input"
              type="email"
              name=""
              id=""
              placeholder="Email"
            />
          </div>
          <div>
            <label>Phone number</label>
            <input
              type="Text"
              className="login-input"
              placeholder="Phone number"
            />
          </div>
          <div>
            <label>Card Number</label>
            <input
              className="login-input"
              id="ccn"
              type="tel"
              inputmode="numeric"
              pattern="[0-9\s]{13,19}"
              autocomplete="cc-number"
              maxlength="19"
              placeholder="1234 1234 1234 1234"
            />
          </div>
          <div className="card_info">
            <div>
              <label>Expiry</label>
              <input placeholder="MM/YY" />
            </div>
            <div>
              <label>CVV</label>
              <input type="numericmode" placeholder="CVV" />
            </div>
          </div>

          <div className="card_info">
            <div>
              <label>Country</label>
              <input type="Country" placeholder="Country" />
            </div>
            <div>
              <label>Postal Code</label>
              <input inputmode="numeric" placeholder="Postal Code" />
            </div>
          </div>
          <div className="t_and_c">
            <input type="checkbox" name="" id="checkbox" />
            <label>
              Please check to acknowledge our Privacy & Terms Policy
            </label>
          </div>
          <Link to="/EventsFacilityBookingConfirmed">
            <button>Pay S$100.00</button>
          </Link>
        </div> */}

        {/* <div className="checkout_form">
          <div>
            <label>Name</label>
            <input
              className="login-input"
              type="text"
              name=""
              id=""
              placeholder="Name"
              value={name ? name : nName}
              onChange={(e) => {
                if (name) {
                  return;
                }
                setnName(e.target.value);
              }}
            />
          </div>
          <div>
            <label>Email</label>
            <input
              className="login-input"
              type="email"
              name=""
              id=""
              placeholder="Email"
              value={email ? email : nEmail}
              onChange={(e) => {
                if (email) {
                  return;
                }
                setnEmail(e.target.value);
              }}
            />
          </div>
          
          <div>
            <label>Phone number</label>
            <input
              type="Text"
              className="login-input"
              placeholder="Phone number"
              value={phone ? phone : nPhone}
              onChange={(e) => {
                if (phone) {
                  return;
                }
                setnPhone(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              fontWeight: "700",
              fontSize: "14px",
              lineHeight: "165%",
              letterSpacing: "0.005em",
              color: "#111729",
              flexDirection: "column",
            }}
          >
            <label>Card Number</label>
            <input
              className="login-input"
              id="ccn"
              type="tel"
              inputmode="numeric"
              pattern="[0-9\s]{13,19}"
              autocomplete="cc-number"
              maxlength="19"
              placeholder="1234 1234 1234 1234"
            />
          </div>
          <div className="card_info">
            <div>
              <label>Expiry</label>
              <input placeholder="MM/YY" />
            </div>
            <div>
              <label>CVV</label>
              <input type="numericmode" placeholder="CVV" />
            </div>
          </div>

          <div className="card_info d-flex justify-content-between mt-2">
            <div
              style={{
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "165%",
                letterSpacing: "0.005em",
                color: "#111729",
                flexDirection: "column",
              }}
            >
              <label>Country</label>
              <input type="Country" placeholder="Country" />
            </div>
            <div>
              <label>Postal Code</label>
              <input inputmode="numeric" placeholder="Postal Code" />
            </div>
          </div>
        
        </div> */}
      </div>
    </div>
  );
}

export default EventsFaciltiyCheckout;

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "rgb(240, 57, 122)",
      color: "rgb(240, 57, 122)",
      fontSize: "16px",
      fontFamily: '"Open Sans", sans-serif',
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#CFD7DF",
      },
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238",
      },
    },
  },
};

const inputStyle = {
  iconColor: "#c4f0ff",
  color: "#ff0",
  fontWeight: "500",
  fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  fontSize: "16px",
  fontSmoothing: "antialiased",
  border: "1px solid black",
  ":-webkit-autofill": {
    color: "#fce883",
  },
  "::placeholder": {
    color: "#87BBFD",
  },
};

// const CardInputWrapper = styled.div`
//   border: 2px solid #00f;
//   border-radius: 8px;
//   padding: 20px 4px;
// `;
